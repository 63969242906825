import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Steve Cardenas"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Steve Cardenas"
        videoUrl="https://vimeo.com/498102344/4653cf52bd"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.steveCardenasImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Steve Cardenas
            </Text>
            <Text level="1" mb="1.2rem">
              Renowned Guitarist and Educator
            </Text>
            <Box>
              <Link to="https://www.instagram.com/stevecardenasmusic/" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link
                to="https://www.facebook.com/Steve-Cardenas-Music-504103240072838/?__xts__[%2Ffacebook]"
                as="a"
              >
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Steve Cardenas is a New York City jazz guitarist. Steve was a
              longstanding member of the{" "}
              <strong>Paul Motian Electric Bebop Band </strong>,{" "}
              <strong>Steve Swallow Quintet</strong> and{" "}
              <strong>Joey Baron's</strong>
              band, <strong>Killer Joey</strong>. He is currently a member of
              the{" "}
              <strong>
                Charlie Haden Liberation Music Orchestra, Ben Allison & Free
                Think, John Patitucci Electric Guitar Quartet, Adam Nussbaum Lead Belly Project
              </strong>{" "}
              and <strong>Jon Cowherd Mercy Project</strong>.
            </Text>
            <Text level="2">
              Steve has also been in groups led by{" "}
              <strong>Madeleine Peyroux</strong>, <strong>Norah Jones</strong>,{" "}
              <strong>Eliane Elias</strong>, <strong>Marc Johnson</strong>,{" "}
              <strong>Paul McCandless</strong> and <strong>Mark Isham.</strong>
            </Text>
            <Text level="2">
              He has toured extensively throughout Europe, North and South
              America and Asia, performing at international jazz festivals,
              theaters, and clubs. Steve is on over 100 recordings as a sideman
              has released six recordings as a leader, his latest being ‘Blue
              Has A Range’ on Sunnyside Records.
            </Text>

            <Text level="2">
              Steve is on faculty at{" "}
              <strong>The New School for Jazz and Contemporary Music</strong> in
              New York City. He has been on the faculty at the{" "}
              <strong>
                California Institute of the Arts, Siena Summer Jazz Workshop,
                Stanford Jazz Workshop, Banff International Workshop in Jazz and
                Creative Music,
              </strong>{" "}
              and <strong>Brubeck Summer Jazz Colony</strong>. Steve is
              co-author, with editor Don Sickler, of the{" "}
              <strong>Thelonious Monk Fakebook</strong>, released by Hal Leonard
              Publishing.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Steve Cardenas" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    steveCardenasImage: file(
      relativePath: { eq: "steveCardenas-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
